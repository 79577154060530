import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link, browserHistory } from 'react-router';
import OperatorLessor from './OperatorLessor';
import { Tooltip } from 'reactstrap';
import { TableContainer,Table,TableHead,TableRow,TableBody, TableCell,DialogActions, Chip, Tooltip as MuiTooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { ToolTipHover, InfoIcon } from '../../shared'
import { assetStatus, imgStoragePath, assetsType,pbhRangeCondition,displayDateFormat} from '../../constants';
import { getLocalStorageInfo, getFileSize } from '../../utils';
import { globalGetService } from '../../globalServices'
import techSpecIcon from '../../shared/assets/img/tech_specs_icon.svg';
import viewIcon from '../../shared/assets/img/view_icon.svg';
import config from '../../config';

export default class AssetsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordTooltip: false,
      technicalTooltip: false,
      assetViewTooltip: false,
      marksumTooltip: false,
      locationTooltipOpen:false,
      countryTooltipOpen:false,
      variableRentTooltip:false,
      open : false
    };
  }
  toggle(type) {
    this.setState({
      [type]: !this.state[type],
    });
  }

  redirectAsset = (event, application) => {
  event.preventDefault();
  event.stopPropagation();
  let assetList = this.props.assetList
  this.setState({ pageLoader: true })
  globalGetService('console/' + `${assetsType[assetList.asset_type]}` + '/' + assetList.slug + '/', {})
    .then(response => {
      this.setState({ pageLoader: false })
      if (response.data.statusCode == 200) {
        let assetInfo = response.data.data
        if (application === 'records') {
          if (assetInfo.records) {
            if (assetInfo.records.technical_records) {
              window.open(`${config.domain.records}${assetsType[assetList.asset_type]}/${assetList.slug}/data-room/technical/${assetInfo.records.technical_records.uuid}${assetList.archive_status ? '?archived-assets' : ''}`, '_blank')
            } else if (assetInfo.records.inspection_records) {
              window.open(`${config.domain.records}${assetsType[assetList.asset_type]}/${assetList.slug}/data-room/inspection/${assetInfo.records.inspection_records.uuid}${assetList.archive_status ? '?archived-assets' : ''}`, '_blank')
            } else if (assetInfo.records.contract_records) {
              window.open(`${config.domain.records}${assetsType[assetList.asset_type]}/${assetList.slug}/data-room/contract/${assetInfo.records.contract_records.uuid}${assetList.archive_status ? '?archived-assets' : ''}`, '_blank')
            } else if (assetInfo.records.contract_records) {
              window.open(`${config.domain.records}${assetsType[assetList.asset_type]}/${assetList.slug}/data-room/discrepancy/${assetInfo.records.contract_records.uuid}${assetList.archive_status ? '?archived-assets' : ''}`, '_blank')
            }
          }
        } else if (application === 'lease') {
          window.open(assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`)
        } else if (application === 'projects') {
          if (assetInfo && assetInfo.technical && assetInfo.technical.work_order_slug) {
            window.open(`${config.domain.projects}${assetsType[assetList.asset_type]}/${assetList.slug}/technical/${assetInfo.technical.work_order_slug}/forms/${assetInfo.technical.form_slug}/${assetInfo.technical.form}`, '_blank')
          } else { window.open(`${config.domain.projects}`, '_blank') }
        } else if (application === 'maintenance') {
          window.open(`${config.domain.maintenance}${assetsType[assetList.asset_type]}/${assetList.slug}/maintenance/airframe`)
        }
      }
    })

}

  render() {
    const { assetList ,activeTab} = this.props;
    let url = "";
    const userInfo = getLocalStorageInfo()
    if (getLocalStorageInfo().user.user_role == 'lessee') {
      if (assetList && assetList.contract && Object.keys(assetList.contract).length) {
        url = assetsType[assetList.asset_type] + "/" + assetList.slug + '/contract/utilization';
      }
    } else {
      url = '/asset-view/' + assetsType[assetList.asset_type] + "/" + assetList.slug;
    }
    let clickable = false
    if (assetList.is_active) {
      clickable = true
    }
    if (assetList.is_active && (assetList.shared_asset != undefined && assetList.shared_asset != null && assetList.shared_asset == 0)) {
      clickable = false
    }
    if ((!assetList.is_active && assetList.data_transfer && Object.keys(assetList.data_transfer).length && assetList.data_transfer.value != 1)) {
      clickable = true
    }
    url = config.domain.subDomian + 'technical-specification/' +
      (assetList.asset_type == 1 ? 'aircraft'
        : assetList.asset_type == 2 ? 'engine'
          : assetList.asset_type == 3 ? 'apu'
            : assetList.asset_type == 4 ? 'lg'
              : assetList.asset_type == 5 ? 'propeller'
                : assetList.asset_type == 6 ? "fan"
                  : assetList.asset_type == 7 ? "hpc"
                    : assetList.asset_type == 8 ? "hpt"
                      : "lpt") + '/' + assetList.slug


    return (
      <Link className="asset-list">
       {
         getLocalStorageInfo().defaultLessor.id===442 ?
         <Row className="flex-centered">
           <Col xs="2" md="2">
           <Link to={ getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
             <div className="flex-centered msn-block">
               <div style={{ display: 'inline', width: '20%' }}>
                 <Link style={clickable ? {} : { cursor: 'not-allowed', opacity: '0.6' }} to={clickable ? '/asset-view/' + assetsType[assetList.asset_type] + '/' + assetList.slug : null}>
                   <img src={imgStoragePath + (assetList.asset_type == 1 ? "icon_Aircraft.png" : (assetList.asset_type == 2) ? "icon_engine.png" : assetList.asset_type == 3 ? "icon_apu.svg" : assetList.asset_type == 4 ? 'icon_lg.svg' : assetList.asset_type == 5 ? 'icon_propeller.svg' : 'icon_engine.png')} alt="img" width="32" />
                 </Link>
               </div>
               <Link style={clickable ? { display: 'inline', width: '80%', paddingLeft: '5px', } : { display: 'inline', paddingLeft: '5px', width: '80%', cursor: 'not-allowed', opacity: '0.5' }}>
                 <span style={{fontSize:'12px'}}>
                  {assetList.asset_type === 1 ?  assetList.aircraft_type.name ? assetList.aircraft_type.name : assetList.engine_type.name : ''}{assetList.asset_type === 1 ? assetList.model && ' / ' + assetList.model: ''}{assetList.asset_type === 2 ? assetList.engine_type.name : ''}{assetList.asset_type === 3 ? assetList.apu_type.name : ''}{assetList.asset_type == 5 ? assetList.model? assetList.model :null : null}
                 </span>
                 <h4 style={{fontSize:'12px'}}>
                   {assetList.asset_type == 1 ? 'MSN ' + assetList.msn : assetList.asset_type == 2 ? 'ESN ' + assetList.esn : assetList.asset_type == 3 ? 'APU ' + assetList.serial_number : assetList.asset_type == 4 ? 'LG ' + assetList.serial_number : assetList.asset_type == 5 ? 'PROPELLER ' + assetList.serial_number : assetList.asset_type == 6 || assetList.asset_type == 7 || assetList.asset_type == 8 ? assetList.esn.toUpperCase() : assetList.esn.toUpperCase()}</h4>
                 {
                   assetList.ownership_type !== undefined && assetList.ownership_type.value == 2 && assetList.owner_portfolio.name && assetList.owner_portfolio.name != '' ?
                     <span style={{ marginTop: '3px' }}><span style={{ borderRadius: '2px' }} className="portfolio-type">Managed</span><InfoIcon id={assetList.slug + "portfolio"} /></span>
                     : null
                 }
                 {
                   assetList.ownership_type && assetList.ownership_type.value == 2 && assetList.owner_portfolio.name && assetList.owner_portfolio.name != '' ?
                     <ToolTipHover placement="top" tagetId={assetList.slug + "portfolio"}>
                       Portfolio: {assetList.owner_portfolio.name}
                     </ToolTipHover> : null
                 }
               </Link>
             </div>
             </Link>
           </Col>
           <Col xs="1" md="1" >
           <Link to={getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
             <h6 style={{fontSize:'12px'}} className="theta">{assetList.lessor_name && assetList.lessor_name.name ? assetList.lessor_name.name : '--'}</h6>
           </Link>
           </Col>
           <Col xs="1" md="1" style={{fontSize:'12px'}} >
           <Link to={ getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
               <OperatorLessor slug={assetList.slug + 'operator'} obj={assetList.lessee ? assetList.lessee : assetList.operator} />
           </Link>
           </Col>
               <Col xs="1" md="1" >
                 <Link to={ getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
                  <h6 style={{fontSize:'12px'}} className="theta">{assetList.next_use && assetList.next_use ? assetList.next_use : '--'}</h6>
                 </Link>
               </Col>

             {
               activeTab == 'all' || activeTab == '1' ?
             <Col xs="2" md="1">
               <Link to={getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
                 <h6 style={{fontSize:'12px'}}  className="theta" id={assetList.slug + "onHoverRegistration"}>{assetList.asset_type == 1 ? assetList.current_registration_number ? assetList.current_registration_number : '--' : '--'}</h6>
                   {  getLocalStorageInfo().defaultLessor.id===442 ?
                     <Tooltip placement="left-start"  isOpen={this.state.countryTooltipOpen} autohide={false} target={assetList.slug + "onHoverRegistration"}toggle={() => this.toggle('countryTooltipOpen')}>
                      {assetList.country ? assetList.country : '--'}
                   </Tooltip>
                    : null}
                    </Link>

               </Col>: null
             }
             {
                 activeTab == 'all' || activeTab == '1' ?
                 <Col xs="1" md="1" >
                 <Link to={getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
                   <h6 style={{fontSize:'12px'}} className="theta" id={assetList.slug + "onHoverLocation"}>{assetList.location && assetList.location ? assetList.location : '--'} </h6>
                   <Tooltip placement="right"  isOpen={this.state.locationTooltipOpen} autohide={false}  target={assetList.slug + "onHoverLocation"} toggle={() => this.toggle('locationTooltipOpen')}>
                    Airport Code:- {assetList.airport_code ? assetList.airport_code : '--'}
                 </Tooltip>
                 </Link>
                 </Col>
                 :
                 <Col xs="1" md="2" >
                 <Link to={getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
                   <h6 style={{fontSize:'12px'}} className="theta" id={assetList.slug + "onHoverLocation"}>{assetList.location && assetList.location ? assetList.location : '--'} </h6>
                   <Tooltip placement="top-right"  isOpen={this.state.locationTooltipOpen} autohide={false}  target={assetList.slug + "onHoverLocation"} toggle={() => this.toggle('locationTooltipOpen')}>
                    Airport Code:- {assetList.airport_code ? assetList.airport_code : '--'}
                 </Tooltip>
                 </Link>
                 </Col>
               }
               {
                   activeTab == 'all' || activeTab == '1' ?
                  <Col xs="4" md="2">
                    <Link to={getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
                     <h6 className="theta" >
                       <span  style={{fontSize:'12px',fontWeight:'400'}} className={"status-" + assetList.status.value}>{getLocalStorageInfo().defaultLessor.id === 348 ? assetList.status.value === 1 ? 'Operational' : 'AOG' : assetList.status.label ? assetList.status.label : '--'}</span>
                     </h6>
                     <h6 style={{width:'100px',display: 'flex', flexDirection: 'column', justifyContent: 'center',textAlign: 'center',fontSize:'12px'}}>
                     { getLocalStorageInfo().defaultLessor.id===442 ?
                        assetList.status && assetList.status.value===1 ?
                        <div className="theta" style={{ background: '#e6e6e6', marginTop: '3px', fontSize:'12px'}}>
                          { assetList.end_lease_date ? moment(assetList.end_lease_date).format('LL').toUpperCase() : '--'}
                        </div> :
                        assetList.status && assetList.status.value===2 ?
                        <div className="theta" style={{ background: '#e6e6e6', marginTop: '3px'}}>
                          { assetList.previous_lessee && " EX- " + assetList.previous_lessee.toUpperCase()}
                        </div> : null :
                        <div className="theta" style={{background: '#e6e6e6', textAlign: 'center',marginTop: '3px'}}>
                          { assetList.sub_status && assetList.sub_status.label && " " + assetList.sub_status.label}
                        </div>
                      }
                     </h6>
                     </Link>
                   </Col> :

               <Col xs="4" md="2">
               <Link to={ getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
                 <h6>
                   <span  style={{width:'150px',fontSize:'12px',fontWeight:'400',fontFamily: 'Roboto, Helvetica, Arial, sans-serif'}} className={"status-" + assetList.status.value}>{getLocalStorageInfo().defaultLessor.id === 348 ? assetList.status.value === 1 ? 'Operational' : 'AOG' : assetList.status.label ? assetList.status.label : '--'}</span>
                 </h6>
                 <h6 style={{width:'150px',display: 'flex', flexDirection: 'column', justifyContent: 'center',textAlign: 'center',fontSize:'12px'}}>
                  { getLocalStorageInfo().defaultLessor.id===442 ?
                    assetList.status && assetList.status.value===1 ?
                    <div className="theta" style={{ background: '#e6e6e6', marginTop: '3px', fontSize:'12px'}}>
                      { assetList.end_lease_date ? moment(assetList.end_lease_date).format('LL').toUpperCase() : '--'}
                    </div> :
                    assetList.status && assetList.status.value===2 ?
                    <div className="theta" style={{ background: '#e6e6e6', marginTop: '3px'}}>
                      {assetList.previous_lessee && " EX- " + assetList.previous_lessee.toUpperCase()}
                    </div> : null :
                    <div className="theta" style={{background: '#e6e6e6', textAlign: 'center',marginTop: '3px'}}>
                        {assetList.sub_status && assetList.sub_status.label && " " + assetList.sub_status.label}
                    </div>
                  }
                 </h6>
                 </Link>
               </Col>
              }
                <Col xs="1" md="1" >
                 {
                 assetList.basic_rent && assetList.basic_rent.length ?
                 typeof assetList.basic_rent==='string' ?<span style={{fontSize:'12px'}}>{ assetList.basic_rent} </span>:
                 <Button onClick={() => this.setState({ open: !this.state.open })} style={{fontSize:'12px', textTransform: 'capitalize'}}>
                 Variable Rent  <InfoIcon id={assetList.slug + "variableRent"}/>
                 <Tooltip placement="top" isOpen={this.state.variableRentTooltip} target={assetList.slug + "variableRent"} toggle={() => this.toggle('variableRentTooltip')}>
                   Please click on info icon to view the Range conditions of Variable rent
                 </Tooltip>
                   <Dialog open={this.state.open}>
                   <p style={{textAlign:"center",marginTop:'20px',marginBottom:'20px',fontSize:'20px'}}>Variable Rent</p>
                         <TableContainer  style={{width:'600px'}}>
                         <p style={{textAlign:"center",color:'blue'}}>Flight Hours</p>
                           <Table md={{ minWidth:250 }} aria-label="simple table">
                             <TableHead>
                              <TableRow>
                               <TableCell style={{paddingLeft:'15px'}} >Range Condition</TableCell>
                                 <TableCell align="right" >min</TableCell>
                                   <TableCell align="right" >max</TableCell>
                                   <TableCell align="right" >Rate Type</TableCell>
                                   <TableCell align="right" >Rate</TableCell>
                               </TableRow>
                             </TableHead>
                             <TableBody >
                               {
                                 typeof assetList.basic_rent!=="string" ?
                                    <>
                                    {
                                    assetList.basic_rent.map((bitem,bindex)=>{
                                       return(
                                         bitem.type=== 1 ?
                                      <TableRow style={{textAlign:'center'}}>
                                          <TableCell>{pbhRangeCondition.filter(item => item.id === parseInt(bitem.ratio_range)).length ? pbhRangeCondition.filter(item => item.id === parseInt(bitem.ratio_range))[0].label : '---'}</TableCell>
                                           <TableCell align="right" >{bitem.min}</TableCell>
                                           <TableCell align="right" >{bitem.max}</TableCell>
                                           <TableCell align="right" >{bitem.rental_rate_type===2 ? "Per Unit" : "Fixed"}</TableCell>
                                           <TableCell align="right" >{bitem.rate}</TableCell>
                                       </TableRow>
                                         : null
                                       )
                                      })
                                   }
                                    </>
                                  :null
                               }

                               </TableBody>
                            </Table>
                            <p style={{textAlign:"center",color:'blue',marginTop:'15px'}}>Flight Cycle</p>
                              <Table md={{ minWidth:250 }} aria-label="simple table">
                                <TableHead>
                                 <TableRow>
                                  <TableCell style={{paddingLeft:'15px'}} >Range Condition</TableCell>
                                    <TableCell align="right" >min</TableCell>
                                      <TableCell align="right" >max</TableCell>
                                      <TableCell align="right" >Rate Type</TableCell>
                                      <TableCell align="right" >Rate</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody >
                                  {
                                    typeof assetList.basic_rent!=="string" ?
                                       <>
                                       {
                                       assetList.basic_rent.map((bitem,bindex)=>{
                                          return(
                                            bitem.type=== 2 ?
                                         <TableRow style={{textAlign:'center'}}>
                                             <TableCell>{pbhRangeCondition.filter(item => item.id === parseInt(bitem.ratio_range)).length ? pbhRangeCondition.filter(item => item.id === parseInt(bitem.ratio_range))[0].label : '---'}</TableCell>
                                              <TableCell align="right" >{bitem.min}</TableCell>
                                              <TableCell align="right" >{bitem.max}</TableCell>
                                              <TableCell align="right" >{bitem.rental_rate_type===2 ? "Per Unit" : "Fixed"}</TableCell>
                                              <TableCell align="right" >{bitem.rate}</TableCell>
                                          </TableRow>
                                            : null
                                          )
                                         })
                                      }
                                       </>
                                     :null
                                  }

                                  </TableBody>
                               </Table>

                               <p style={{textAlign:"center",color:'blue',marginTop:'15px'}}>Block Hours</p>
                                 <Table md={{ minWidth:250 }} aria-label="simple table">
                                   <TableHead>
                                    <TableRow>
                                     <TableCell style={{paddingLeft:'15px'}} >Range Condition</TableCell>
                                       <TableCell align="right" >min</TableCell>
                                         <TableCell align="right" >max</TableCell>
                                         <TableCell align="right" >Rate Type</TableCell>
                                         <TableCell align="right" >Rate</TableCell>
                                     </TableRow>
                                   </TableHead>
                                   <TableBody >
                                     {
                                       typeof assetList.basic_rent!=="string" ?
                                          <>
                                          {
                                          assetList.basic_rent.map((bitem,bindex)=>{
                                             return(
                                               bitem.type=== 4 ?
                                            <TableRow style={{textAlign:'center'}}>
                                                <TableCell>{pbhRangeCondition.filter(item => item.id === parseInt(bitem.ratio_range)).length ? pbhRangeCondition.filter(item => item.id === parseInt(bitem.ratio_range))[0].label : '---'}</TableCell>
                                                 <TableCell align="right" >{bitem.min}</TableCell>
                                                 <TableCell align="right" >{bitem.max}</TableCell>
                                                 <TableCell align="right" >{bitem.rental_rate_type===2 ? "Per Unit" : "Fixed"}</TableCell>
                                                 <TableCell align="right" >{bitem.rate}</TableCell>
                                             </TableRow>
                                               : null
                                             )
                                            })
                                         }
                                          </>
                                        :null
                                     }

                                     </TableBody>
                                  </Table>
                            <p style={{textAlign:"center",color:'blue',marginTop:'15px'}}>Custom (with Payment Schedule)</p>
                              <Table md={{ minWidth:250 }} aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="right" >Period</TableCell>
                                    <TableCell align="right" >Payment Date</TableCell>
                                    {
                                      assetList.basic_rent_type.currency==="USD" ?
                                      <TableCell align="right" >Reference Value</TableCell>
                                      : null
                                    }
                                    <TableCell align="right" >{assetList.basic_rent_type.currency==="EUR" ? " Euro Rent (EUR)" : "Rent"}</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody >
                                {
                                  typeof assetList.basic_rent!=="string" ?
                                     <>
                                     {
                                     assetList.basic_rent.map((bitem,bindex)=>{
                                        return(
                                          <TableRow style={{textAlign:'center'}}>
                                           <TableCell align="right" >{bitem.period}</TableCell>
                                            <TableCell align="right" >{bitem.payment_date ? moment(bitem.payment_date).format(displayDateFormat):''}</TableCell>
                                            {
                                              assetList.basic_rent_type.currency==="USD" ?
                                              <TableCell align="right" >{bitem.reference_value && "US$" + bitem.reference_value + ".00"}</TableCell>
                                              : null
                                            }
                                            <TableCell align="right" >{assetList.basic_rent_type.currency==="EUR" ? bitem.rent && "€" + bitem.rent + ".00" : bitem.rent && "US$" + bitem.rent + ".00"}</TableCell>
                                          </TableRow>
                                        )
                                       })
                                    }
                                     </>
                                     :null
                                }
                                </TableBody>
                                </Table>
                           </TableContainer>
                           <DialogActions>
                               <Button onClick={() => this.setState({ open: !this.state.open })} color="primary" variant="contained">
                                 OK
                               </Button>
                           </DialogActions>
                         </Dialog>
                         </Button>
                 :
                 assetList.basic_rent ? assetList.basic_rent : '--'
               }
                 </Col>
               <Col xs="3" md="2" style={{display: 'flex', justifyContent: 'center',}}>
               <ul className="list-inline" style={{ textAlign: 'center',width:'200px'}}>
                 <li className="list-inline-item"  onClick={(event) => this.redirectAsset(event, 'maintenance')}>
                   <span
                     style={clickable ? {} : { cursor: 'not-allowed', opacity: '0.5' }}
                     onClick={(evt) => { evt.preventDefault(); evt.stopPropagation(); window.open(url, '_blank').focus() }}>
                     <img style={{ width: '20px', cursor:'pointer', height:'20px'}} id={assetList.slug + "technical_specs"} src={techSpecIcon} alt="Tech spec icon" />
                     <Tooltip placement="top" isOpen={this.state.technicalTooltip} target={assetList.slug + "technical_specs"} toggle={() => this.toggle('technicalTooltip')}>
                       Technical Specification
                     </Tooltip>
                     </span>
                  </li>
                  <li className="list-inline-item" onClick={(event) => this.redirectAsset(event, 'lease')} >
                      <span style={clickable ? {} : { cursor: 'not-allowed', opacity: '0.5'}}>
                      <img style={{ width: '20px', cursor:'pointer', height:'20px'}} id={assetList.slug + "Lease"} src={imgStoragePath + 'phase-2/LeaseIcon.svg'} alt="Lease icon" />
                       <Tooltip placement="top" isOpen={this.state.leaseTooltip} target={assetList.slug + "Lease"} toggle={() => this.toggle('leaseTooltip')}>
                        Lease
                        </Tooltip>
                      </span>
                  </li>
                 <li className="list-inline-item" onClick={(event) => this.redirectAsset(event, 'records')} >
                       <span
                         style={clickable ? {} : { cursor: 'not-allowed', opacity: '0.5' }}>
                         <img style={{ width: '20px', cursor:'pointer', height:'20px'}} id={assetList.slug + "Records"} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Dataroom icon" />
                         <Tooltip placement="top" isOpen={this.state.recordsTooltip} target={assetList.slug + "Records"} toggle={() => this.toggle('recordsTooltip')}>
                           Data Room
                         </Tooltip>
                       </span>
                     </li>

                 <li className="list-inline-item" onClick={(event) => this.redirectAsset(event, 'projects')} >
                   <span
                     style={clickable ? {} : { cursor: 'not-allowed', opacity: '0.5' }}>
                     <img style={{ width: '20px', cursor:'pointer', height:'20px'}} id={assetList.slug + "Projects"} src={imgStoragePath + 'phase-2/ProjectsIcon.svg'} alt="Projects icon" />
                     <Tooltip placement="top" isOpen={this.state.projectsTooltip} target={assetList.slug + "Projects"} toggle={() => this.toggle('projectsTooltip')}>
                       Projects
                     </Tooltip>
                   </span>
                 </li>
                   <li className="list-inline-item" onClick={(event) => this.redirectAsset(event, 'maintenance')} >
                    <span
                      style={clickable ? {} : { cursor: 'not-allowed', opacity: '0.5' }}>
                      <img style={{ width: '20px', cursor:'pointer', height:'20px'}} id={assetList.slug + "Maintenance"} src={imgStoragePath + 'phase-2/MaintenanceIcon.svg'} alt="Maintenance icon" />
                      <Tooltip placement="top" isOpen={this.state.maintenanceTooltip} target={assetList.slug + "Maintenance"} toggle={() => this.toggle('maintenanceTooltip')}>
                        Maintenance
                      </Tooltip>
                    </span>
                  </li>
           </ul>
          </Col>
         </Row>
         :
         <Row className="flex-centered">
           <Col xs="2" md="2">
           <Link to={ getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.security_groups[0] && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization` : assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
             <div className="flex-centered msn-block">
               <div style={{ display: 'inline', width: '20%' }}>
                 <Link style={clickable ? {} : { cursor: 'not-allowed', opacity: '0.6' }} to={clickable ? '/asset-view/' + assetsType[assetList.asset_type] + '/' + assetList.slug : null}>
                   <img src={imgStoragePath + (assetList.asset_type == 1 ? "icon_Aircraft.png" : (assetList.asset_type == 2) ? "icon_engine.png" : assetList.asset_type == 3 ? "icon_apu.svg" : assetList.asset_type == 4 ? 'icon_lg.svg' : assetList.asset_type == 5 ? 'icon_propeller.svg' : 'icon_engine.png')} alt="img" width="32" />
                 </Link>
               </div>
               <Link style={clickable ? { display: 'inline', width: '80%', paddingLeft: '5px', } : { display: 'inline', paddingLeft: '5px', width: '80%', cursor: 'not-allowed', opacity: '0.5' }}>

                 <span style={{fontSize:'12px'}}>
                 {assetList.asset_type === 1 ?  assetList?.aircraft_type?.name ? assetList?.aircraft_type?.name||'' : assetList?.engine_type?.name||'' : ''}{assetList.asset_type === 1 ? assetList.model && ' / ' + assetList.model: ''}{assetList.asset_type === 2 ? assetList?.engine_type?.name||'' : ''}{assetList.asset_type === 3 ? assetList?.apu_type?.name ||'': ''}{assetList.asset_type == 5 ? assetList.model? assetList.model :null : null}
                 </span>
                 <h4 style={{fontSize:'12px'}}> {assetList.asset_type == 1 ? 'MSN ' + assetList.msn : assetList.asset_type == 2 ? 'ESN ' + assetList.esn : assetList.asset_type == 3 ? 'APU ' + assetList.serial_number : assetList.asset_type == 4 ? 'LG ' + assetList.serial_number : assetList.asset_type == 5 ? 'PROPELLER ' + assetList.serial_number : `${assetsType[assetList.asset_type].toUpperCase()} ${assetList.esn.toUpperCase()}` } </h4>
                 {
                   assetList.ownership_type !== undefined && assetList.ownership_type.value == 2 && assetList.owner_portfolio.name && assetList.owner_portfolio.name != '' ?
                     <span style={{ marginTop: '3px' }}><span style={{ borderRadius: '2px' }} className="portfolio-type">Managed</span><InfoIcon id={assetList.slug + "portfolio"} /></span>
                     : null
                 }
                 {
                   assetList.ownership_type && assetList.ownership_type.value == 2 && assetList.owner_portfolio.name && assetList.owner_portfolio.name != '' ?
                     <ToolTipHover placement="top" tagetId={assetList.slug + "portfolio"}>
                       Portfolio: {assetList.owner_portfolio.name}
                     </ToolTipHover>
                     : null
                 }
               </Link>
             </div>
             </Link>
           </Col>
           {
             activeTab == 'all' || activeTab == '1' ?
             <Col xs="1" md="1" >
             <Link to={getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
               <h6 style={{fontSize:'12px'}} className="theta">{assetList.lessor_name && assetList.lessor_name.name ? assetList.lessor_name.name : '--'}</h6>
             </Link>
             </Col> :
             <Col xs="1" md="2" >
             <Link to={getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
               <h6 style={{fontSize:'12px'}} className="theta">{assetList.lessor_name && assetList.lessor_name.name ? assetList.lessor_name.name : '--'}</h6>
             </Link>
             </Col>
           }

           <Col xs="1" md="2" >
           <Link to={getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
             <h6 style={{fontSize:'12px'}} className="theta">{assetList.owner && assetList.owner.name ? assetList.owner.name : '--'}</h6>
             </Link>
           </Col>
           {
             activeTab == 'all' || activeTab == '1' ?
             <Col xs="1" md="1" style={{fontSize:'12px'}} >
             <Link to={getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
                 <OperatorLessor slug={assetList.slug + 'operator'} obj={assetList.lessee ? assetList.lessee : assetList.operator} />
             </Link>
             </Col> :
             <Col xs="1" md="2" style={{fontSize:'12px'}} >
             <Link to={assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
                 <OperatorLessor slug={assetList.slug + 'operator'} obj={assetList.lessee ? assetList.lessee : assetList.operator} />
             </Link>
             </Col>
           }

             {
               activeTab == 'all' || activeTab == '1' ?
             <Col xs="2" md="2">
               <Link to={getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
                 <h6 style={{fontSize:'12px'}}  className="theta" id={assetList.slug + "onHoverRegistration"}>{assetList.asset_type == 1 ? assetList.current_registration_number ? assetList.current_registration_number : '--' : '--'}</h6>
                   {  getLocalStorageInfo().defaultLessor.id===442 ?
                     <Tooltip placement="left-start"  isOpen={this.state.countryTooltipOpen} autohide={false} target={assetList.slug + "onHoverRegistration"}toggle={() => this.toggle('countryTooltipOpen')}>
                      {assetList.country ? assetList.country : '--'}
                   </Tooltip>
                    : null}
                    </Link>

               </Col>: null
             }
             {
              activeTab == 'all' || activeTab == '1' ?
               <Col xs="4" md="2">
                   <Link to={getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
                     <h6 className="theta" >
                       <span  style={{fontSize:'12px',fontWeight:'400'}} className={"status-" + assetList.status.value}>{getLocalStorageInfo().defaultLessor.id === 348 ? assetList.status.value === 1 ? 'Operational' : 'AOG' : assetList.status.label ? assetList.status.label : '--'}</span>
                     </h6>
                     <h6 style={{width:'100px',display: 'flex', flexDirection: 'column', justifyContent: 'center',textAlign: 'center',fontSize:'12px'}}>
                        <div className="theta" style={{background: '#e6e6e6', textAlign: 'center',marginTop: '3px'}}>
                          {assetList.sub_status && assetList.sub_status.label && " " + assetList.sub_status.label}
                        </div>
                     </h6>
                    </Link>
               </Col> :
               <Col xs="4" md="2">
               <Link to={getLocalStorageInfo().user.security_groups.length > 0 && getLocalStorageInfo().user.security_groups[0].id === 3 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/${assetList.active_lease}/utilization`: assetList.active_lease_status == 1 ? `${assetsType[assetList.asset_type]}/${assetList.slug}/contract/list/${assetList.active_lease}`  : `/${assetsType[assetList.asset_type]}/${assetList.slug}/contracts`} style={clickable ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: '0.5' }}>
                 <h6>
                   <span  style={{width:'150px',fontSize:'12px',fontWeight:'400',fontFamily: 'Roboto, Helvetica, Arial, sans-serif'}} className={"status-" + assetList.status.value}>{getLocalStorageInfo().defaultLessor.id === 348 ? assetList.status.value === 1 ? 'Operational' : 'AOG' : assetList.status.label ? assetList.status.label : '--'}</span>
                 </h6>
                 <h6 style={{width:'150px',display: 'flex', flexDirection: 'column', justifyContent: 'center',textAlign: 'center',fontSize:'12px'}}>
                    <div className="theta" style={{background: '#e6e6e6', textAlign: 'center',marginTop: '3px'}}>
                      {assetList.sub_status && assetList.sub_status.label && " " + assetList.sub_status.label}
                    </div>
                 </h6>
                 </Link>
               </Col>
              }
               <Col xs="3" md="2" style={{display: 'flex', justifyContent: 'center',}}>
               <ul className="list-inline" style={{ textAlign: 'center',width:'200px'}}>
                 <li className="list-inline-item"  onClick={(event) => this.redirectAsset(event, 'maintenance')}>
                   <span
                     style={clickable ? {} : { cursor: 'not-allowed', opacity: '0.5' }}
                     onClick={(evt) => { evt.preventDefault(); evt.stopPropagation(); window.open(url, '_blank').focus() }}>
                     <img style={{ width: '20px', cursor:'pointer', height:'20px'}} id={assetList.slug + "technical_specs"} src={techSpecIcon} alt="Tech spec icon" />
                     <Tooltip placement="top" isOpen={this.state.technicalTooltip} target={assetList.slug + "technical_specs"} toggle={() => this.toggle('technicalTooltip')}>
                       Technical Specification
                     </Tooltip>
                     </span>
                  </li>
           </ul>
         </Col>
         </Row>
       }
      </Link>
    )
  }
}
