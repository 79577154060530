import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Drawer, DialogActions, DialogContent, DialogTitle, Grid, TextField, FormControlLabel, Checkbox,Link } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import AddIcon from '@material-ui/icons/Add';
import { AutocompleteCreatable } from '../../../shared_elements';
import CloseIcon from '@material-ui/icons/Close';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { checkApiStatus, checkPermission, getLocalStorageInfo , toastFlashMessage} from '../../../utils_v2';
import { addEditContractApi } from '../apiServices';
import { fieldDateFormat, backendDateFormat, assetsType } from '../../../constants';
import { fieldValidation, dateRangeValidation } from '../../../utils_v2/formValidation';
import { addContactObj, errorCode } from '../';
import { imgStoragePath } from '../../../constants';
import { uploadLesseeLogoAc } from '../../../shared/actionCreators';
import Creatable from 'react-select/lib/Creatable';
import { FieldLabel } from '../../../applications/contracts/Elements';
import rollingLoader from '../../../shared/assets/img/rollingLoader.gif'
import { regexConstants } from '../../../constants/regEx';
class ContractCRU extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      contract:{},
      error:{},
      newLessee:false,
      lesseeLogoLoader:false
    }
    this.addEditContractApi = addEditContractApi.bind(this);
    this.uploadLesseeLogoAc = uploadLesseeLogoAc.bind(this);
  }
  handleError = (value, key) => {
    this.updateErrorField(key, errorCode[key][fieldValidation({...errorCode[key+'Obj'], fieldval: value})]);
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: message
      }
    }))
  }
  onFieldChange = (e, keyParam, data) => {
    const { contract } = this.state;
    if ((keyParam === 'lessee_id' || keyParam === 'operator_id') && data) {
      if (keyParam == 'lessee_id') {
        // if (typeof (data.id) == "string") {
        //   this.setState(prevState => ({
        //     ...prevState,
        //     contract: {
        //       ...prevState.contract,
        //       lessee_id: {
        //         name: data.name,
        //         logo: '',
        //         contact_address: '',
        //         country: ''
        //       }
        //     },
        //     newLessee: true
        //   }))
        // }
        // else {
          this.setState(prevState => ({
            ...prevState,
            contract: {
              ...prevState.contract,
              lessee_id: data
            },
            // data: data.id,
            // newLessee: false
          }))
        // }
      }
      if (keyParam === 'lessee_id' && contract.operator_id && data.name === contract.operator_id.name) {
        this.updateErrorField('lessee_id' , 'Lessee cannot be same as Operator')
        this.updateFormFields('lessee_id' , '')
      }else if (keyParam === 'operator_id' && contract.lessee_id && data.name === contract.lessee_id.name) {
        this.updateErrorField('operator_id' , 'Operator cannot be same as Lessee')
        this.updateFormFields('operator_id' , '')
      }else {
        if (keyParam == 'lessee_id') {
          if (typeof (data.id) == "string") {
            delete data['className']
            // delete data['id']
            data={
              ...data,
              contact_address: data?.contact_address ? data.contact_address :'',
              country:data?.country? data.country : ''
            }
            this.updateFormFields(keyParam, data);
          }
          else{
            this.updateFormFields(keyParam, data);
          }
          this.updateErrorField(keyParam, '');
        }
        else{
          this.updateFormFields(keyParam, data);
          this.updateErrorField(keyParam, '');
        }
      }
    }else{
      this.updateFormFields(keyParam, data);
    }
    if(keyParam == 'lease_term' && moment(contract.contractual_start_date).isValid() ){
      this.updateFormFields('contractual_end_date', moment(contract.contractual_start_date).add(data, 'M').format(backendDateFormat))
    }
    if(keyParam == 'contractual_start_date' && contract?.lease_term){
      this.updateFormFields('contractual_end_date', moment(data).add(contract.lease_term, 'M'))
    }
    if(keyParam === 'same_operator_lessee' && data === true){
      this.updateFormFields('operator_id', null);
    }
    if(keyParam == 'contractual_start_date' && moment(contract.contractual_end_date).isValid()){
      this.updateFormFields('lease_term', Math.round(moment(contract.contractual_end_date).diff(moment(data), 'months', true)));
    }else if(keyParam == 'contractual_end_date' && moment(contract.contractual_start_date).isValid()){
      this.updateFormFields('lease_term', Math.round(moment(data).diff(moment(contract.contractual_start_date), 'months', true)));
    }
  }
  updateFormFields = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      contract: {
        ...prevState.contract,
        [key]:value
      }
    }));
  }

  // updateNewLessee = (type, value) => {
  //   this.setState(prevState => ({
  //     ...prevState,
  //     contract:{
  //       ...prevState.contract,
  //       lessee_id:{
  //         ...prevState.contract.lessee_id,
  //         [type]:value
  //       }
  //     }
  //   }))
  // }
  // uploadLogo = (file) => {
  //   if (['image/jpeg','image/jpg','image/png'].includes(file?.type)) {
  //     if(file.size <= 5242880){
  //     let formData = new FormData()
  //     formData.append('file', file)
  //     this.setState({ lesseeLogoLoader: true })
  //     this.uploadLesseeLogoAc(formData)
  //       .then(response => {
  //         this.setState({ lesseeLogoLoader: false })
  //         if (response.data.statusCode == 200) {
  //           this.setState(prevState => ({
  //             ...prevState,
  //             contract: {
  //               ...prevState.contract,
  //               lessee_id: {
  //                 ...prevState.contract.lessee_id,
  //                 logo: response.data.data.path,
  //                 url: response.data.data.url
  //               }
  //             }
  //           }))
  //           toastFlashMessage(response.data.message, 'success')
  //         } else {
  //           toastFlashMessage(response.data.message, 'error')
  //         }
  //       })
  //     }
  //     else{
  //       toastFlashMessage( 'Please upload file max upto 5MB', 'error')
  //     }
  //   }
  //   else {
  //     toastFlashMessage('Please upload .jpg/.jpeg or .png file only', 'error')
  //   }
  // }

  addEditContract = () => {
    const { contract, newLessee } = this.state;
    let payload  = Object.assign({}, contract);
    payload = {
      ...payload,
      // lessee_id: contract.lessee_id && contract.lessee_id.id ? contract.lessee_id.id:'',
      operator_id: contract.operator_id && contract.operator_id.id ? contract.operator_id.id:'',
      contractual_end_date: contract.contractual_end_date? contract.contractual_end_date :'',
      lease_duration_type: contract.lease_duration_type && contract.lease_duration_type.value ? contract.lease_duration_type.value:'',
      asset_type: this.props.params.type == 'engine' ? 2 : this.props.params.type === 'aircraft' ? 1 : this.props.params.type === 'apu' ? 3 : this.props.params.type === 'propeller' ? 5 : this.props.params.type === 'lg' ? 4 : this.props.params.type === 'fan' ? 6 : this.props.params.type === 'hpc' ? 7 : this.props.params.type === 'hpt' ? 8 : 9 ,
    
    }
    
    let validationInputs = {
      lease_type:errorCode['lease_type'][fieldValidation({...errorCode['lease_typeObj'], fieldval: payload.lease_type})],
      lessee_id:errorCode['lessee_id'][fieldValidation({...errorCode['lessee_idObj'], fieldval: contract.lessee_id})],
      operator_id: !contract.same_operator_lessee ? errorCode['operator_id'][fieldValidation({...errorCode['operator_idObj'], fieldval: payload.operator_id.toString()})]:'',
      contractual_start_date:errorCode['contractual_start_date'][fieldValidation({...errorCode['contractual_start_dateObj'], fieldval: payload.contractual_start_date})],
      lease_duration_type:errorCode['lease_duration_type'][fieldValidation({...errorCode['lease_duration_typeObj'], fieldval: payload.lease_duration_type})],
    }
    if(['2','1'].includes(contract?.lease_duration_type?.value)){
      validationInputs={
        ...validationInputs,
        contractual_end_date:errorCode['contractual_end_date'][fieldValidation({...errorCode['contractual_end_dateObj'], fieldval: payload.contractual_end_date})],
      }
    }
    if(['2','3'].includes(contract?.lease_duration_type?.value)){
      validationInputs={
        ...validationInputs,
        tsn: errorCode['tsn'][fieldValidation({...errorCode['tsnObj'], fieldval: payload.tsn})],
        lease_duration_hours: errorCode['lease_duration_hours'][fieldValidation({...errorCode['lease_duration_hoursObj'], fieldval: payload.lease_duration_hours})]
      }
      if(contract?.lease_duration_type?.value =='3'){
        validationInputs={
          ...validationInputs,
          time_between_overhaul: errorCode['time_between_overhaul'][fieldValidation({...errorCode['time_between_overhaulObj'], fieldval: payload.time_between_overhaul})],
          last_overhaul_tsn: errorCode['last_overhaul_tsn'][fieldValidation({...errorCode['last_overhaul_tsnObj'], fieldval: payload.last_overhaul_tsn})]
        }
      }
    }
    if(payload.contractual_start_date && payload.contractual_end_date){
      validationInputs = {
        ...validationInputs,
        contractual_start_date: dateRangeValidation(payload.contractual_start_date, payload.contractual_end_date, 30)
      }
    }
    if(newLessee== false){
      payload={
        ...payload,
        lessee_id: payload.lessee_id?.id ? payload.lessee_id?.id:''
      }
    }

    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      if(['',"",null].includes(payload?.contractual_end_date)&& ['',"",null].includes(payload?.lease_term) && payload.lease_duration_type != 3){
        this.props.enqueueSnackbar('Please enter either End date or Lease Term', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
      else{
      this.addEditContractApi(this.props, payload)
      .then(response => {
        if(checkApiStatus(response)){
          this.setState(prevState => ({
            ...prevState,
            modal:false,
            contract:{},
            error:{}
          }));
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
          this.props.getResponseBack();
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }
    }else{
      this.setState({error: validationInputs})
    }
  }
  render(){
    const { modal, contract, error, newLessee } = this.state;
    const { pltConstants, lessee, operator, list, archived_asset } = this.props;
    return(
      <Fragment>
        <Button onClick={() => {this.setState({modal:true, contract:addContactObj, newLessee:false}); this.props.getLesseeList()}} variant="contained" color="primary"><AddIcon fontSize='small'/>Add Lease</Button>
        { modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({modal:false, contract:{}, error:{}})}
          >
            <div style={{width:'670px'}} className="drawer-container">
              <div className="drawer-header" alignItems='center'>
                <span className="left-blk">Add Lease</span>
                <CloseIcon className="close-icon" onClick={()=> this.setState({modal:false})}/>
              </div>
              <div className="drawer-body">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Autocomplete
                      options = {pltConstants.filter(item => item.type === 'lease_type')}
                      getOptionLabel={option => option.label}
                      id="lease_type"
                      value={pltConstants.length && contract.lease_type ? pltConstants.filter(item => item.type === 'lease_type').find(obj => obj.value === contract.lease_type):null}
                      onChange={(e, value) => {this.onFieldChange(e, 'lease_type', value ? value.value:value); this.handleError(value ? value.value:'', 'lease_type')}}
                      renderInput={params => <TextField required {...params} error={error.lease_type ? true:false} helperText={error.lease_type ? error.lease_type:''} onFocus={(e) => this.updateErrorField('lease_type', '')} label="Lease Type" placeholder="Select Lease Type" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                    />
                  </Grid>
                  <div style={newLessee ? {width: '100%', background: '#f3f3f3', margin: '14px', padding: '20px'} : {margin: '14px', width: '100%'}}>
                  <Grid item xs={12}>
                  <Autocomplete
                        options={lessee || []}
                        getOptionLabel={(option) => option?.name}
                        id="lessee_id"
                        value={contract.lessee_id ? contract.lessee_id : null}
                        onChange={(e, value) => this.onFieldChange(e, 'lessee_id', value)}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label="Lessee"
                            margin="normal"
                            error={error.lessee_id ? true:false} 
                            InputLabelProps={{ shrink: true }}
                            helperText={error.lessee_id ? error.lessee_id:''}
                            fullWidth
                            className="custom-select-block add-lessee"
                            placeholder="Select Lessee"
                          />
                        )}
                      />
                  {/* <FieldLabel className="label">Lessee<sup className="reqStar" style={error.lessee_id? {color:'#f44336'}:{color:'#959595'}}> * </sup></FieldLabel>
                  <Creatable
                  value={contract.lessee_id}
                  options={lessee}
                  isClearable={true}
                  onChange={(e)=>this.onFieldChange(contract.lessee_id,'lessee_id', e)}
                  className="custom-select-block add-lessee"
                  placeholder='Add or Select Lessee'
                  valueKey="id"
                  labelKey="name"
                /> */}
                      {/* <h6 className='error-msg'>{error.lessee_id ? error.lessee_id : ''}</h6> */}
                    </Grid>
                    {/* {
                      newLessee ?
                        <Fragment>
                          <Grid item xs={12}>
                            <TextField
                              id="contact_address"
                              label="Contact Address"
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 50 }}
                              value={contract.lessee_id?.contact_address ? contract.lessee_id?.contact_address : ''}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => this.updateNewLessee('contact_address', e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="country"
                              label="Country"
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 50 }}
                              value={contract.lessee_id?.country ? contract.lessee_id?.country : ''}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => this.updateNewLessee('country', e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: '20px' }}>
                          { contract?.lessee_id?.url ? 'Change Logo' : 'Upload Logo' }<img src={imgStoragePath + 'phase-2/upload_template.png'} style={{marginLeft:'5px'}} width={20} />
                            <input type="file" accept={["image/jpg","image/jpeg","image/png"]} onChange={(e) => this.uploadLogo(e.target.files[0])} style={{ position: 'absolute', left: '0px', opacity: '0', cursor:'pointer', width:'180px' }} />
                            <p style={{fontSize:'11px'}}>Please upload .jpg/.jpeg or .png file only (Max upto 5MB)</p>
                          {
                            this.state.lesseeLogoLoader ?
                            <div style={{ backgroundImage: "url("+ rollingLoader+")", height:'100px' , backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroudPosition: 'center' }}> </div>
                            : contract.lessee_id?.url && !this.state.lesseeLogoLoader?
                             <div style={{ backgroundImage: "url(" + contract?.lessee_id?.url + ")", height:contract?.lessee_id?.url ? '100px':'1px' , maxWidth:'150px', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroudPosition: 'center',marginTop:'10px'  }}> </div> 
                             :null
                          }
                          </Grid>
                        </Fragment> :
                        null
                    } */}
                  </div>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox color="primary" checked={contract.same_operator_lessee} onChange={(e) => this.onFieldChange(e, 'same_operator_lessee', e.target.checked)} value=" Operator is same as Lessee" />
                      }
                      label=" Operator is same as Lessee"
                    />
                  </Grid>
                  { !contract.same_operator_lessee ?
                    <Grid item xs={12}>
                      <Autocomplete
                        options = {operator}
                        getOptionLabel={option => option.name}
                        id="operator"
                        value={contract.operator_id}
                        onChange={(e, value) => {this.onFieldChange(e, 'operator_id', value);}}
                        renderInput={params => <TextField required {...params} error={error.operator_id ? true:false} helperText={error.operator_id ? error.operator_id:''} onFocus={(e) => this.updateErrorField('operator_id', '')} label="Operator" placeholder="Select Operator" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                    </Grid>:null
                  }
                  <Grid item xs={12}>
                    <Autocomplete
                      options={[{ label: 'Fixed Date', value: '1' }, { label: 'Based on Flight Hours or Date', value: '2' }, { label: 'Based on TBO', value: '3' }]}
                      id="leaseEndOptions"
                      getOptionLabel={option => option.label}
                      value={contract.lease_duration_type}
                      onChange={(e, value) => this.onFieldChange(e, 'lease_duration_type', value)}
                      renderInput={params => <TextField required {...params} label="Lease Duration Type" error={error.lease_duration_type? true: false} helperText={error.lease_duration_type? error.lease_duration_type:''} onFocus={(e)=> this.updateErrorField('lease_duration_type','')} placeholder="Select Lease Duration Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        required
                         margin="normal"
                        id="contractual_start_date"
                        label="Start Date"
                        format={fieldDateFormat}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ readOnly: true }}
                        clearable={true}
                        error={error.contractual_start_date ? true : false}
                        helperText={error.contractual_start_date ? error.contractual_start_date : ''}
                        value={contract.contractual_start_date ? contract.contractual_start_date : null}
                        onChange={(data, value) => { this.onFieldChange(value, 'contractual_start_date', data ? moment(data).format(backendDateFormat) : data); this.handleError(data ? data : '', 'contractual_start_date') }}
                        onFocus={(e) => this.updateErrorField('contractual_start_date', '')}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  {
                    ['3'].includes(contract?.lease_duration_type?.value) ?
                      null :
                      <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            required
                            margin="normal"
                            id="contractual_end_date"
                            label="End Date"
                            format={fieldDateFormat}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ readOnly: true }}
                            clearable={true}
                            error={error.contractual_end_date ? true : false}
                            helperText={error.contractual_end_date ? error.contractual_end_date : ''}
                            value={contract.contractual_end_date ? contract.contractual_end_date : null}
                            onChange={(data, value) => { this.onFieldChange(value, 'contractual_end_date', data ? moment(data).format(backendDateFormat) : data); this.handleError(data ? data : '', 'contractual_end_date') }}
                            onFocus={(e) => this.updateErrorField('contractual_end_date', '')}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                  }
                  {
                    ['2', '3'].includes(contract?.lease_duration_type?.value) ?
                      <Grid item xs={6}>
                        <TextField
                          required
                          id="tsn"
                          label="Start TSN"
                          fullWidth
                          margin="normal"
                          error={error.tsn ? true : false}
                          helperText={error.tsn ? error.tsn : ''}
                          value={contract.tsn ? contract.tsn : ''}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? this.updateFormFields('tsn', e.target.value): e.preventDefault() }}
                          onFocus={(e) => this.updateErrorField('tsn', '')}
                          />
                      </Grid>
                      : null
                  }
                  {
                    ['2', '3'].includes(contract?.lease_duration_type?.value) ?
                    <Grid item xs={6}>
                        <TextField
                          required
                          id="lease_duration_hours"
                          label="Lease Duration (Hours)"
                          fullWidth
                          margin="normal"
                          value={contract.lease_duration_hours ? contract.lease_duration_hours : ''}
                          InputLabelProps={{ shrink: true }}
                          error={error.lease_duration_hours ? true : false}
                          helperText={error.lease_duration_hours ? error.lease_duration_hours : ''}
                          onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? this.updateFormFields('lease_duration_hours', e.target.value): e.preventDefault() }}
                          onFocus={(e) => this.updateErrorField('lease_duration_hours', '')}
                        />
                      </Grid> : null
                  }
                  {
                    ['3'].includes(contract?.lease_duration_type?.value) ?
                      <>
                        <Grid item xs={6}>
                          <TextField
                          required
                            id="time_between_overhaul"
                            label="Time Between Overhaul"
                            fullWidth
                            margin="normal"
                            value={contract.time_between_overhaul ? contract.time_between_overhaul : ''}
                            InputLabelProps={{ shrink: true }}
                            error={error.time_between_overhaul ? true : false}
                            helperText={error.time_between_overhaul ? error.time_between_overhaul : ''}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ?this.updateFormFields('time_between_overhaul', e.target.value): e.preventDefault() }}
                            onFocus={(e) => this.updateErrorField('time_between_overhaul', '')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                          required
                            id="last_overhaul_tsn"
                            label="Last Overhaul TSN"
                            fullWidth
                            margin="normal"
                            value={contract.last_overhaul_tsn ? contract.last_overhaul_tsn : ''}
                            InputLabelProps={{ shrink: true }}
                            error={error.last_overhaul_tsn ? true : false}
                            helperText={error.last_overhaul_tsn ? error.last_overhaul_tsn : ''}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? this.updateFormFields('last_overhaul_tsn', e.target.value): e.preventDefault() }}
                            onFocus={(e) => this.updateErrorField('last_overhaul_tsn', '')}
                          />
                        </Grid>
                      </> : null
                  }
                  {['3'].includes(contract?.lease_duration_type?.value) ?
                    null :
                    <Grid item xs={6}>
                      <TextField
                        id="lease_term"
                        label="Lease Term (Months)"
                        fullWidth
                        margin="normal"
                        value={contract.lease_term ? contract.lease_term : ''}
                        onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(e,'lease_term', e.target.value): e.preventDefault() }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <TextField
                      id="rental_account_number"
                      label="Rental Account Number"
                      fullWidth
                      margin="normal"
                      inputProps={{ maxLength: 50 }}
                      value={contract.rental_account_number ? contract.rental_account_number:''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'rental_account_number', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="mr_account_number"
                      label="MR Account Number"
                      fullWidth
                      margin="normal"
                      inputProps={{ maxLength: 50 }}
                      value={contract.mr_account_number ? contract.mr_account_number:''}
                      InputLabelProps={{shrink: true}}
                      onChange={(e) => this.onFieldChange(e, 'mr_account_number', e.target.value)}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="drawer-footer">
                <Button onClick={this.addEditContract} color="primary" variant="contained">Save</Button>
                <Button onClick={() => this.setState({modal:false, contract:{}, error:{}})} color="primary" style={{marginLeft:'8px'}}>Cancel</Button>
              </div>
            </div>
          </Drawer>:null
        }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(ContractCRU));
